<!--
 * @Author: kankanol 1317894008@163.com
 * @Date: 2022-06-27 16:34:32
 * @LastEditors: kankanol 1317894008@163.com
 * @LastEditTime: 2022-07-01 11:01:33
 * @FilePath: \irds_uid:\apro\coduck\coduck\coduck_student\src\views\works\components\pygame\sizeset.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-form
    :model="formInfo"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
  >
    <el-form-item label="素材宽" prop="title">
      <el-row>
        <el-col :span="20"
          ><el-input-number style='width:100%' v-model="formInfo.width"></el-input-number>
        </el-col>
        <el-col :span="2" style='padding-left:8px'> px</el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="素材高" prop="title">
      <el-row>
        <el-col :span="20">
          <el-input-number style='width:100%' v-model="formInfo.height"></el-input-number>
        </el-col>
        <el-col :span="2" style='padding-left:8px'>px</el-col>
      </el-row>
    </el-form-item>
    <el-form-item style="text-align: center; padding-right: 50px">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="saveFile">提交</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
// 作品素材重命名
export default {
  props: {
    formInfos: {
      type: Object,
      default() {
        return {};
      },
    },
    // rules: {
    //   type: Object,
    //   default() {
    //     return {};
    //   },
    // },
  },
  created() {
    this.formInfo = { ...this.formInfos };
  },
  watch: {
    formInfos: {
      handler(val) {
        this.formInfo = { ...this.formInfos };
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      formInfo: {},
      rules: {
        width: [
          { required: true, message: "素材宽度不可为空", trigger: "blur" },
        ],
        height: [
          { required: true, message: "素材高度不可为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    saveFile() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit("sizeSetFile", this.formInfo);
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>