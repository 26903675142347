import $axios from '@/utils/request'
// 获取服务器文件内容
export function getFileText(url) {
    return $axios.fGetFileText(url, {})
}

// 上传作品通用接口
export function uploadFile(data) {
    return  $axios.fPost("/common/file/uploadFile", data || {})
}
