import { render, staticRenderFns } from "./rename.vue?vue&type=template&id=38936d7d"
import script from "./rename.vue?vue&type=script&lang=js"
export * from "./rename.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/frontend_coduck_business_lcPT/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38936d7d')) {
      api.createRecord('38936d7d', component.options)
    } else {
      api.reload('38936d7d', component.options)
    }
    module.hot.accept("./rename.vue?vue&type=template&id=38936d7d", function () {
      api.rerender('38936d7d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Pygame/components/rename.vue"
export default component.exports