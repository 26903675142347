var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.formInfo, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "素材宽", prop: "title" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.formInfo.width,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInfo, "width", $$v)
                      },
                      expression: "formInfo.width",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { "padding-left": "8px" }, attrs: { span: 2 } },
                [_vm._v(" px")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "素材高", prop: "title" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.formInfo.height,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInfo, "height", $$v)
                      },
                      expression: "formInfo.height",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { "padding-left": "8px" }, attrs: { span: 2 } },
                [_vm._v("px")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticStyle: { "text-align": "center", "padding-right": "50px" } },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveFile } },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }