import $axios from '@/utils/request'
/**
 * 获取保利威 token vid
 * @param {*} data 
 * @returns 
 */
export function getVideoToken(data) {
    const url = '/edu/video/getVideoToken'
    return $axios.fGet(url, data)
}

/**
 * 获取培训课程保利威 token vid
 * @param {*} data 
 * @returns 
 */
export function getTrainVideoToken(data) {
    const url = '/meredu/video/getVideoToken'
    return $axios.fGet(url, data)
}