<!--
 * @Author: kankanol 1317894008@163.com
 * @Date: 2022-07-15 10:18:29
 * @LastEditors: kankanol 1317894008@163.com
 * @LastEditTime: 2022-08-12 10:29:58
 * @FilePath: \irds_uid:\apro\coduck\coduck\coduck_student\src\views\works\components\pygame\components\preview.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div style="text-align: center; padding-bottom: 16px">
      <!-- 图片预览 -->
      <img
        v-if="scanInfo.type == '图片'"
        :src="scanInfo.url"
        alt=""
        style="height: 300px;max-width:100%"
      />
      <!-- 音频预览 -->
      <audio
        v-else-if="scanInfo.type == '音频'"
        :src="scanInfo.url"
        controls
        height="100"
        width="100"
      >
        您的浏览器不支持 audio 标签。
      </audio>
      <h3 style="padding-bottom: 16px">{{ scanInfo.name }}</h3>
    </div>
  </div>
</template>
<script>
export default {
  name: "preview",
  props:{
    scanInfo: {
      type: Object,
      default:()=>{}
    }
  },
  data() {
    return {};
  },
  methods: {
    
  },
};
</script>