<template>
  <el-dialog
    top="20px"
    title="列表信息"
    :visible.sync="worksInfo"
    v-if="worksInfo"
    width="900px"
    @close="close"
    class="dialog-style"
  >
    <el-row style="height: 500px; overflow-x: auto">
      <template v-for="(item, index) in WorksList">
        <el-col
          :key="index"
          :span="8"
          style="padding: 8px; display: inline-block"
        >
          <div class="item-style" @click="showItem(item)">
            <figure style='text-align:center;height:200px;padding:20px 10px 0;'>
              <img
                height="140"
                :src="item.cover ? item.cover : './python3/imgs/python.svg'"
                alt=""
              />
              <figcaption>
                <h3 :title='item.title' class='item-title'>
                  {{ item.title }}
                </h3>
              </figcaption>
            </figure>
          </div>
        </el-col>
      </template>
    </el-row>
    <el-pagination
      background
      :page-size="pageInfo.pageSize"
      :total="pageInfo.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      layout="total, sizes, prev, pager, next, jumper"
      style="padding: 10px 20px; text-align: center"
    />
  </el-dialog>
</template>
<script>
/*
 * @Author: lidianzhong
 * @Date: 2021-08-06 11:53:56
 * @Last Modified by: lidianzhong
 * @Last Modified time: 2021-08-06 11:58:11
 * @descript: Python作品列表页面
 */

import { getWorksList } from "@/api/works/index";
export default {
  props: {},
  data() {
    return {
      worksInfo: false,
      pageInfo: {
        currentPage: 1,
        total: 0,
        pageSize: 10, //
        pageCount: 0, //总页数
        needCount: true,
      },
      pageSize: 10,
      WorksList: [],
    };
  },
  created() {
    this.getWorksList();
  },
  methods: {
    //分页
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getWorksList();
    },
   
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
      this.getWorksList();
    },
    getWorksList() {
      let params = {
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize, //
        needCount: this.pageInfo.needCount,
        category: "Pygame",
      };
      getWorksList(params).then((res) => {
        this.WorksList = res.body.list;
        this.pageInfo.total = res.body.total;
      });
    },
    //
    showItem(item) {
      this.$emit("showItem", item);
    },
    open() {
      this.worksInfo = true;
    },
    close() {
      this.worksInfo = false;
    },
  },
};
</script>
<style scoped lang="scss">
.item-box {
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 460px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #eee;
}
.item-style {
  border: 1px solid #eee;
  border-radius: 4px;
  cursor: pointer;
}
.item-style:hover {
  transform: translate(0, -4px);
  transition: all 0.6s;
}
.item-title {
  text-align: center;
  padding: 8px;
  word-break:keep-all;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;  
}
</style>
<style lang="scss">
.dialog-style {
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>