var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.worksInfo
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog-style",
          attrs: {
            top: "20px",
            title: "列表信息",
            visible: _vm.worksInfo,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.worksInfo = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { height: "500px", "overflow-x": "auto" } },
            [
              _vm._l(_vm.WorksList, function (item, index) {
                return [
                  _c(
                    "el-col",
                    {
                      key: index,
                      staticStyle: { padding: "8px", display: "inline-block" },
                      attrs: { span: 8 },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "item-style",
                          on: {
                            click: function ($event) {
                              return _vm.showItem(item)
                            },
                          },
                        },
                        [
                          _c(
                            "figure",
                            {
                              staticStyle: {
                                "text-align": "center",
                                height: "200px",
                                padding: "20px 10px 0",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  height: "140",
                                  src: item.cover
                                    ? item.cover
                                    : "./python3/imgs/python.svg",
                                  alt: "",
                                },
                              }),
                              _c("figcaption", [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "item-title",
                                    attrs: { title: item.title },
                                  },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
          _c("el-pagination", {
            staticStyle: { padding: "10px 20px", "text-align": "center" },
            attrs: {
              background: "",
              "page-size": _vm.pageInfo.pageSize,
              total: _vm.pageInfo.total,
              "current-page": _vm.pageInfo.currentPage,
              "page-sizes": [10, 20, 30, 50],
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }